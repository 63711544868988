.title {
  border-bottom: 1px solid;
  border-top: 1px solid;
  padding: 20px;
  margin-top: 20px;
  text-align: center;
  font-weight: 400;
}
.booksSlider {
  display: flex;
  height: 50vh;
  position: relative;
  overflow-x: hidden;
  margin: 30px 0;
  color: white;
  padding: 10px 20px 20px 20px;
}
.item {
  background-color: var(--primary-color);
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
  width: 350px;
  border: none;
  flex-direction: column;
  padding: 5px;
  display: flex;
}
.item img {
  width: 60%;
}
.item .product-title {
  font-size: 23px;
  font-weight: 300;
  padding: 0 5px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.0em;
  color: white;
  text-decoration: none;
}
@media (max-width: 720px) {
  .item .product-title{
    line-height: 0.8em;
  }
}
.item p {
  border: 1px solid;
  width: 100%;
  text-align: center;
  padding: 5px 0;
  margin: auto;
  font-size: 18px;
  color: #e9971a;
  margin-bottom: 0;
}
.icons {
  border: 1px solid;
  width: 100%;
  padding: 5px;
  text-align: center;
}
.icons i {
  padding: 0 60px;
  font-size: 40px !important;
  color: white;
}
.icon-book {
  font-size: 30px !important;
  cursor: pointer;
}
.icon-arrow {
  width: 50px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white !important;
  background-color: #222;
  border-radius: 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  z-index: 2;
  font-size: 32px !important;
}
.icon-arrow-left {
  left: 10px;
}
.icon-arrow-right {
  right: 10px;
}
.wrapper {
  display: flex;
  transition: all 0.5s ease;
}
