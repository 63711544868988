.model{
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.62);  
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content{
    background-color: var(--main-color);
    width: 80%;
    height: 80%;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    color: black;
    font-weight: 300;
}
.content .title{
    font-size: 25px;
    font-weight: 500;
}
.content .container{
    display: flex;
}

.content .container h1{
    padding-top: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid;
}
.content .container p{
    padding-top: 15px;
    font-size: 18px;
}
.icons-model{
    display: flex;
    justify-content: center;
    position: absolute;
    left: 50%;
    right: 50%;
    bottom: 25px;
    cursor: pointer;
    /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2); */
}
.icon-close{
    padding-left: 20px;
}
.info-book span{
    color: #96661d;
}
@media (max-width: 720px) {
    .content{
        width: 90%;
        height: 90%;
        padding: 10px;
    }
    .content .container{
        flex-direction: column;
    }
    .icon-close{
        margin-top: 150%;
    }
    .content .container h1{
        font-size: 20px;
    }
    .content .container img{
        display: none;
    }
    .content .container p{
        font-size: 16px;
    }
}


