@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&family=Quantico:wght@400;700&family=Shantell+Sans:wght@400;500&family=Ubuntu:wght@300;400&display=swap');

:root {
  --main-color: #e2d2c4;
  --primary-color: #493323;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Ubuntu, serif;
}
body {
  background-color: var(--main-color);
}
ul {
  list-style: none;
}
input:focus {
  outline: none;
}
link,a{
  text-decoration: none;
  color: white;
}
