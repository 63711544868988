.header {
  height: 250px;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--primary-color);
}

/* Start Header Top */
.header-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20%;
  padding: 0 30px;
  border-bottom: 1px solid var(--main-color);
  color: #fff;
}
.header-top-phone,
.header-top-text,
.header-top-link {
  font-size: 24px;
  font-weight: 400;
}
.icon-top-header {
  margin-right: 6px !important;
}

/* End Header Top */
/* Start Header Middle */
.header-middle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50%;
  padding: 0 30px;
  position: relative;
}
.header-middle-logo {
  font-weight: 400;
  font-size: 26px;
  color: #fff;
}

.icon-logo {
  font-size: 30px;
}
.header-middle-search-box {
  width: 500px;
  background-color: #fff;
  height: 50px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.header-middle-search-input {
  width: 100%;
  height: 100%;
  border: none;
  padding-left: 22px;
  font-size: 22px;
}
.icon-search {
  position: absolute;
  top: 20%;
  right: 3%;
  font-size: 30px;
  color: #83745e;
}
.icon-cart {
  color: #fff;
  font-size: 30px;
  cursor: pointer;
}
.header-middle-cart-notifications {
  position: absolute;
  top: 20%;
  right: 1%;
  width: 25px;
  height: 25px;
  background: red;
  color: #fff;
  text-align: center;
  border-radius: 50%;
}
.nav-menu {
  display: none;
}
/* End Header Middle */

/* Start Navbar */
.navbar {
  background: var(--primary-color);
  color: white;
  box-shadow: 0 0 1px 1px white;
  height: 30%;
}
.navbar .links {
  position: relative;
  top: 30%;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10%;
  text-decoration: none;
  color: white;
}

/* End Header */

/* Responsive */

@media (max-width: 910px) {
  .navbar .links {
    font-size: 16px;
  }
  .header-middle-search-box {
    width: 450px;
    height: 50px;
  }
}
@media (max-width: 720px) {
  .header-top-menu {
    display: block;
    font-size: 30px;
    cursor: pointer;
  }
  .header-top-text {
    display: none;
  }
  .header-middle {
    flex-wrap: wrap;
    padding: 0 30px;
    height: 80%;
  }
  .header-middle-logo {
    margin: auto;
    padding-top: 10px;
  }
  .nav-menu {
    display: block;
    text-align: center;
    color: white;
    font-size: 30px;
  }
  .navbar {
    display: none;
    width: 100%;
    height: 40vh;
    transition: all 1s ease;
    position: sticky;
    z-index: 100;
  }
  .navbar .links {
    position: inherit;
    flex-direction: column;
    padding-top: 20px;
  }
  .navbar .links li {
    font-size: 18px;
    padding: 5px;
  }
  .header-middle-cart-notifications {
    top: 68%;
    left: 10%;
  }
}
@media (max-width: 600px) {
  .header-middle-search-box {
    width: 100%;
  }
  .header-middle-search-input {
    font-size: 15px;
  }
}
