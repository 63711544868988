.container{
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
@media (max-width: 720px) {
  .container{
      width: 100%;
  }
}