.each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
  }
  
  .each-slide-effect span {
    padding: 20px;
    background: #efefef;
    border-top-left-radius: 10px;
    font-size: 22px;
  }
.react-slideshow-container .default-nav{
    padding: 10px;
    margin: 10px;
  }