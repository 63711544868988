.login{
    margin: auto;
    width: 35%;
    margin-top: 50px;
    margin-bottom: 50px;
    position: relative;
}
.login h1{
    text-align: center;
    font-size: 35px;
    margin: 15px 0;
}
.login form{
    display: flex;
    padding: 15px;
    font-size: 22px;
    background: var(--primary-color);
    color: white;
    border-radius: 5px;
    flex-direction: column;
    height: 50vh;
}
.login form input,label{
    font-size: 18px;
    padding: 10px;
    border-radius: 5px;
    border: none;
}
.login label{
    font-size: 22px;
}
.login button{
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-top: 5px;
    background: var(--main-color);
    color: var(--primary-color);
    font-size: 20px;
    cursor: pointer;
}
.login button:hover{
    background: rgb(242, 242, 242);
    color: var(--primary-color);
}
.login a{
    margin-top: 15px;
    font-size: 20px;
}

/* Responsive */
@media (max-width:990px){
    .login{
        width: 60%;
    }
}
@media (max-width: 720px){
    .login{
        width: 70%;
    }
}